import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES_INSIDE } from 'routes/list.routes';
import { api } from 'services/api';
import PROFILES from 'utils/profiles';
import { AuthDataResponse, AuthSocial, User } from 'utils/types';
import { useTheme } from './ThemeContext';
import { useCallProfiles } from 'hooks/useLogin';

interface AuthContextType {
  user: User | null;
  profile?: PROFILES;
  profileId?: string;
  signIn: (data: AuthDataResponse) => void;
  logOut: () => void;
  changeProfile: (profile: string) => void;
  changeProfilePhoto: (photo: string) => void;
  socialLogin: (data: AuthSocial) => Promise<void>;
}

export const AuthContext = createContext({} as AuthContextType);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const { callProfiles } = useCallProfiles();
  const { pathname } = useLocation();
  const { whiteLabel } = useTheme();
  const getUser = localStorage.getItem('@user:analytica');
  const profileUser = localStorage.getItem('@user:profile');

  const [profile, setProfile] = useState<PROFILES>();
  const [user, setUser] = useState<User | null>(
    (getUser && JSON.parse(getUser)) || null
  );

  useEffect(() => {
    if (user) {
      localStorage.setItem(
        '@user:profile',
        JSON.stringify(
          user?.profiles?.find((item) => item.id === user.activeProfileId)?.code
        )
      );
    }
  }, []);

  useEffect(() => {
    if (profileUser) {
      const profileStorage = JSON.parse(profileUser);

      setProfile(profileStorage);
    }
  }, [profileUser, pathname]);

  function cleanState(): void {
    setUser(null);

    delete api.defaults.headers.common['Authorization'];

    localStorage.removeItem('@user:analytica');
    localStorage.removeItem('@token:analytica');
    localStorage.removeItem('@refresh-token:analytica');
    localStorage.removeItem('@user:profile');

    localStorage.removeItem('@content:filter');
    localStorage.removeItem('@content:institutionsFilter');
    localStorage.removeItem('@content:anosLetivosFilter');
    localStorage.removeItem('@content:areaConhecimentoFilter');
    localStorage.removeItem('@content:materiasFilter');
    localStorage.removeItem('@content:temasFilter');
    localStorage.removeItem('@content:subtemasFilter');
    localStorage.removeItem('@content:assuntosFilter');

    localStorage.removeItem('@questions:filter');
    localStorage.removeItem('@questions:materiasFilter');
    localStorage.removeItem('@questions:temasFilter');
    localStorage.removeItem('@questions:subtemasFilter');
    localStorage.removeItem('@questions:assuntosFilter');
    localStorage.removeItem('@questions:bancasFilter');
    localStorage.removeItem('@questions:anoBancasFilter');
    localStorage.removeItem('@questions:statusFilter');

    localStorage.removeItem('@activitiesGenerator:filter');
    localStorage.removeItem('@activitiesGenerator:materiasFilter');
    localStorage.removeItem('@activitiesGenerator:temasFilter');
    localStorage.removeItem('@activitiesGenerator:subtemasFilter');
    localStorage.removeItem('@activitiesGenerator:assuntosFilter');
    localStorage.removeItem('@activitiesGenerator:atividadeTipo');

    localStorage.removeItem('@activitiesModels:filter');
    localStorage.removeItem('@activitiesModels:materiasFilter');
    localStorage.removeItem('@activitiesModels:temasFilter');
    localStorage.removeItem('@activitiesModels:subtemasFilter');
    localStorage.removeItem('@activitiesModels:assuntosFilter');
    localStorage.removeItem('@activitiesModels:tipoAtividadesFilter');
    return;
  }

  function signIn({
    token,
    refresh_token,
    user,
    external = false,
  }: AuthDataResponse): void {
    cleanState();

    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const userSelectedProfile = user?.profiles?.find(
      (item) => item?.id === user?.activeProfileId
    )?.code;

    setUser(user);

    setProfile(userSelectedProfile);

    localStorage.setItem('@user:profile', JSON.stringify(userSelectedProfile));
    localStorage.setItem('@token:analytica', token);
    localStorage.setItem('@refresh-token:analytica', refresh_token);
    localStorage.setItem('@user:analytica', JSON.stringify(user));

    external
      ? navigate('/conteudo/pesquisar/area-de-conhecimento')
      : userSelectedProfile === PROFILES.GESTOR_REGIONAL ||
          userSelectedProfile === PROFILES.ADMIN_REGIONAL ||
          userSelectedProfile === PROFILES.ADMIN
        ? navigate('/dados-acesso/geral')
        : navigate('/dashboard');

    return;
  }

  function changeProfile(profile: string): void {
    const holdUser = {
      ...(user as User),
      activeProfileId: profile,
    };

    setUser(holdUser);

    localStorage.setItem(
      '@user:profile',
      JSON.stringify(
        holdUser.profiles.find((item) => item.id === holdUser.activeProfileId)
          ?.code
      )
    );

    localStorage.setItem('@user:analytica', JSON.stringify(holdUser));
  }

  function logOut(): void {
    cleanState();

    if (whiteLabel?.nomeFantasia) {
      return navigate(`/${whiteLabel?.path}`);
    }

    navigate('/');

    return;
  }

  function changeProfilePhoto(photo: string): void {
    const holdUser = {
      ...(user as User),
      photoURL: photo,
    };

    setUser(holdUser);

    localStorage.setItem('@user:analytica', JSON.stringify(holdUser));

    return;
  }

  async function socialLogin(data: AuthSocial): Promise<void> {
    cleanState();

    await callProfiles({
      emailOrCpf: data.email,
      sub: data.sub,
      type: 'email',
      socialLogin: true,
      signInF: signIn,
    });

    return;
  }

  useEffect(() => {
    const currentRoute = ROUTES_INSIDE.find((route) =>
      pathname.includes(route.path.replace(':id', ''))
    );
    if (profile) {
      if (
        currentRoute?.profiles &&
        !currentRoute?.profiles?.includes(profile)
      ) {
        cleanState();

        if (whiteLabel?.nomeFantasia) {
          return navigate(`/${whiteLabel?.path}`);
        }

        navigate('/');
      }
    }
  }, [pathname, whiteLabel, profile]);

  useEffect(() => {
    if (
      user &&
      profile !== 'ANALYTICA' &&
      profile !== 'SUPORT' &&
      profile !== 'TEACHER_ANALYTICA'
    ) {
      const intervalId = setInterval(() => {
        api.get('/acesso/tempo');
      }, 60000);

      return () => clearInterval(intervalId);
    }
  }, [user, profile]);

  return (
    <AuthContext.Provider
      value={{
        user,
        profile,
        signIn,
        logOut,
        changeProfile,
        changeProfilePhoto,
        socialLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
