import { lazy } from 'react';
import PROFILES from 'utils/profiles';

const CreateContent = lazy(() => import('../pages/CreateContent'));
const CreatePassword = lazy(() => import('../pages/CreatePassword'));
const CreateQuestion = lazy(
  () => import('../pages/QuestionsBank/CreateQuestion')
);
const Dashboard = lazy(() => import('../pages/Dashboard'));
const FirstAccess = lazy(() => import('../pages/FirstAccess'));
const Login = lazy(() => import('../pages/Login'));
const AcessoEmail = lazy(() => import('../pages/AcessoEmail'));
const MyData = lazy(() => import('../pages/MyData'));
const NeedHelp = lazy(() => import('../pages/NeedHelp'));
const PasswordRecovery = lazy(() => import('../pages/PasswordRecovery'));
const PasswordReset = lazy(() => import('../pages/PasswordReset'));
const Register = lazy(() => import('../pages/Register'));
const MainContent = lazy(() => import('../pages/Content'));
const CalendarFunctionalities = lazy(
  () => import('../pages/CalendarFunctionalities')
);
const MainActivities = lazy(() => import('../pages/Activities'));
const ListActivities = lazy(
  () => import('../pages/Activities/ListActivitiesStudent')
);
const ActivitiesModels = lazy(() => import('../pages/ActivitiesModels'));
const ActivitiesGenerator = lazy(
  () => import('../pages/Activities/ActivitiesGenerator')
);
const ContentSearch = lazy(() => import('../pages/ContentSearch'));
const SubjectSearch = lazy(() => import('../pages/SubjectSearch'));
const Quiz = lazy(() => import('../pages/Quiz'));
const QuizDownloadPage = lazy(() => import('../pages/QuizDownloadPage'));
const ThemeSearch = lazy(() => import('../pages/ThemeSearch'));
const Class = lazy(() => import('../pages/Class'));
const ReviewActivities = lazy(
  () => import('../pages/Activities/ReviewActivities')
);
const ReviewActivity = lazy(() => import('../pages/Activities/ReviewActivity'));
const ReviewActivityStudent = lazy(
  () => import('../pages/Activities/ReviewActivityStudent')
);
const CommunicationWarnings = lazy(
  () => import('../pages/CommunicationWarnings')
);
const CommunicationManagement = lazy(
  () => import('../pages/CommunicationManagement')
);
const ContentRecords = lazy(() => import('../pages/ContentSearch/Analytica'));
const TestSimulation = lazy(() => import('../pages/TestSimulation'));
const Test = lazy(() => import('../pages/TestSimulation/Test'));
const Enem = lazy(() => import('../pages/TestSimulation/Enem'));
const Simulation = lazy(() => import('../pages/TestSimulation/Simulation'));
const MyGraphics = lazy(() => import('../pages/MyGraphics'));
const Graphics = lazy(() => import('../pages/Graphics'));
const Activity = lazy(() => import('../pages/Activities/Activity'));
const MySimulations = lazy(() => import('../pages/MySimulations'));
const ReviewQuestions = lazy(
  () => import('../pages/QuestionsBank/ListQuestion')
);
const Simuladao = lazy(() => import('../pages/TestSimulation/Simuladao'));
const GeneralSimulation = lazy(
  () => import('../pages/TestSimulation/GeneralSimulation')
);
const Vestibular = lazy(() => import('../pages/TestSimulation/Vestibular'));
const CreateWhiteLabel = lazy(
  () => import('../pages/WhiteLabel/CreateWhiteLabel')
);
const CreateWhiteLabelTrail = lazy(
  () => import('../pages/WhiteLabel/CreateWhiteLabelTrail')
);
const ListWhiteLabel = lazy(() => import('../pages/WhiteLabel/ListWhiteLabel'));
const ListWhiteLabelTrails = lazy(
  () => import('../pages/WhiteLabel/ListWhiteLabelTrails')
);
const WhiteLabelTrailYear = lazy(
  () => import('../pages/WhiteLabel/WhiteLabelTrailYear')
);
const Issues = lazy(() => import('../pages/Issues'));
const CommunicationHelpArea = lazy(
  () => import('../pages/CommunicationHelpArea')
);
const ConsultClient = lazy(() => import('../pages/Client/ConsultClient'));
const CreateClient = lazy(() => import('../pages/Client/CreateClient'));
const GoalGenerator = lazy(() => import('../pages/GoalGenerator'));
const Activities = lazy(() => import('../pages/GoalGenerator/Activities'));
const GoalMonitor = lazy(() => import('../pages/GoalMonitor/Teachers'));
const GoalMonitorListShowContent = lazy(
  () => import('../pages/GoalMonitor/Teachers/ListShowContent')
);
const GoalMonitorListActivities = lazy(
  () => import('../pages/GoalMonitor/Teachers/ListActivities')
);
const ContentVisualization = lazy(
  () => import('../pages/GoalGenerator/ContentVisualization')
);
const CreateUser = lazy(() => import('../pages/User/CreateUser'));
const ConsultUser = lazy(() => import('../pages/User/ConsultUser'));
const ConsultPlan = lazy(() => import('../pages/GoalsPlans/ConsultPlan'));
const PlanDetails = lazy(() => import('../pages/GoalsPlans/PlanDetails'));
const GoalConsult = lazy(() => import('../pages/GoalConsult/Teachers'));
const GoalConsultListActivities = lazy(
  () => import('../pages/GoalConsult/Teachers/ListActivities')
);
const GoalConsultListShowContent = lazy(
  () => import('../pages/GoalConsult/Teachers/ListShowContent')
);
const SelectInstituition = lazy(
  () => import('../pages/WhiteLabel/CreateWhiteLabel/SelectInstituition')
);
const Boletim = lazy(() => import('../pages/Boletim'));
const CreateBoletim = lazy(() => import('../pages/Boletim/CreateBoletim'));
const Warnings = lazy(() => import('../pages/Warnings'));
const NotFound = lazy(() => import('../pages/NotFound'));
const Ranking = lazy(() => import('../pages/Ranking'));
const ResultSimulation = lazy(() => import('../pages/ResultSimulation'));
const ConsultStudentsBoletim = lazy(
  () => import('../pages/Boletim/ConsultStudentsBoletim')
);
const ConsultBoletim = lazy(() => import('../pages/Boletim/ConsultBoletim'));
const UpdateQuestion = lazy(
  () => import('../pages/QuestionsBank/UpdateQuestion')
);
const ConsultSchoolYear = lazy(
  () => import('../pages/Client/ConsultSchoolYear')
);
const ConsultRegisteredClasses = lazy(
  () => import('../pages/Client/ConsultRegisteredClasses')
);
const ConsultSchool = lazy(() => import('../pages/Client/ConsultSchool'));
const ConsultSchoolPedagogicalTrail = lazy(
  () => import('../pages/Client/ConsultSchoolPedagogicalTrail')
);
const PedagogicalTrailYear = lazy(
  () =>
    import('../pages/Client/ConsultSchoolPedagogicalTrail/PedagogicalTrailYear')
);
const MyTickets = lazy(() => import('../pages/Ticket/MyTickets'));
const Tickets = lazy(() => import('../pages/Ticket/Tickets'));
const StudentLiveClasses = lazy(() => import('../pages/LiveClass/Student'));
const TeacherLiveClasses = lazy(() => import('../pages/LiveClass/Teacher'));
const TeacherLiveClass = lazy(
  () => import('../pages/LiveClass/Teacher/CreateLive')
);
const Live = lazy(() => import('../pages/LiveClass/Live'));
const RecordedLive = lazy(() => import('../pages/LiveClass/RecordedLive'));
const Groups = lazy(() => import('pages/Groups'));
const DataAcess = lazy(() => import('pages/DataAcess'));
const DataAcessUser = lazy(() => import('pages/DataAcessUser'));

export const ROUTES = [
  {
    element: Login,
    path: '/',
  },
  {
    element: Login,
    path: '/:slug',
  },
  {
    element: NotFound,
    path: '/:slug/404',
  },
  {
    element: AcessoEmail,
    path: '/acesso-com-email',
  },
  {
    element: PasswordRecovery,
    path: '/recuperar-senha',
  },
  {
    element: PasswordReset,
    path: ':whiteLabelName/recuperar-senha/:token',
  },
  {
    element: NeedHelp,
    path: '/preciso-de-ajuda',
  },
  {
    element: FirstAccess,
    path: '/primeiro-acesso',
  },
  {
    element: CreatePassword,
    path: ':whiteLabelName/cadastrar-senha/:token',
  },
  {
    element: Register,
    path: '/cadastro',
  },
];

export const ROUTES_INSIDE = [
  {
    element: Dashboard,
    path: '/dashboard',
    title: 'Dashboard Analytica',
    profiles: [
      PROFILES.STUDENT,
      PROFILES.RESPONSIBLE,
      PROFILES.TEACHER,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.ADMIN,
      PROFILES.SUPORT,
      PROFILES.ANALYTICA,
    ],
  },
  {
    element: MainActivities,
    path: '/atividades/lista',
    title: 'Atividades',
    profiles: [
      PROFILES.TEACHER,
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.ADMIN,
      PROFILES.SUPORT,
      PROFILES.ANALYTICA,
    ],
  },
  {
    element: ActivitiesModels,
    path: '/atividades/modelos',
    title: 'Modelos de atividades',
    profiles: [
      PROFILES.TEACHER,
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.ADMIN,
      PROFILES.SUPORT,
      PROFILES.ANALYTICA,
    ],
  },
  {
    element: ActivitiesGenerator,
    path: '/atividades/gerador',
    title: 'Conteúdo - Gerador de atividades',
    profiles: [
      PROFILES.TEACHER,
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.ADMIN,
      PROFILES.SUPORT,
      PROFILES.ANALYTICA,
    ],
  },
  {
    element: ActivitiesGenerator,
    path: '/atividades/gerador/:id',
    title: 'Conteúdo - Gerador de atividades',
    profiles: [
      PROFILES.TEACHER,
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.ADMIN,
      PROFILES.SUPORT,
      PROFILES.ANALYTICA,
    ],
  },
  {
    element: ReviewActivities,
    path: '/atividades/revisao',
    title: 'Correção de atividades',
    profiles: [
      PROFILES.TEACHER,
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.ADMIN,
      PROFILES.SUPORT,
      PROFILES.ANALYTICA,
    ],
  },
  {
    element: ReviewActivity,
    path: '/atividades/revisao/:id',
    title: 'Correção de atividades',
    profiles: [
      PROFILES.TEACHER,
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.ADMIN,
      PROFILES.SUPORT,
      PROFILES.ANALYTICA,
    ],
  },
  {
    element: ReviewActivityStudent,
    path: '/atividades/revisao/:id/aluno',
    title: 'Correção de atividades',
    profiles: [
      PROFILES.TEACHER,
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.ADMIN,
      PROFILES.SUPORT,
      PROFILES.ANALYTICA,
    ],
  },
  {
    element: MyData,
    path: '/meus-dados',
    title: 'Perfil do',
    profiles: [
      PROFILES.STUDENT,
      PROFILES.RESPONSIBLE,
      PROFILES.TEACHER,
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.ADMIN,
      PROFILES.SUPORT,
      PROFILES.ANALYTICA,
    ],
  },
  {
    element: CreateQuestion,
    path: '/banco-questoes/cadastrar',
    title: 'Banco de Questões - Cadastro de questão',
    profiles: [
      PROFILES.TEACHER,
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.ADMIN,
      PROFILES.SUPORT,
      PROFILES.ANALYTICA,
    ],
  },
  {
    element: ReviewQuestions,
    path: '/banco-questoes/revisar',
    title: 'Banco de Questões - Revisão de questões',
    profiles: [
      PROFILES.TEACHER,
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.ADMIN,
      PROFILES.SUPORT,
      PROFILES.ANALYTICA,
    ],
  },
  {
    element: UpdateQuestion,
    path: '/banco-questoes/revisar/:id',
    title: 'Banco de Questões - Revisão de questões',
    profiles: [
      PROFILES.TEACHER,
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.ADMIN,
      PROFILES.SUPORT,
      PROFILES.ANALYTICA,
    ],
  },
  {
    element: Class,
    path: '/conteudo',
    title: 'Conteúdo',
    profiles: [
      PROFILES.STUDENT,
      PROFILES.TEACHER,
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.ADMIN,
      PROFILES.SUPORT,
      PROFILES.ANALYTICA,
    ],
  },
  {
    element: MainContent,
    path: '/conteudo/lista',
    title: 'Conteúdo - Lista de Conteúdo',
    profiles: [
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.ADMIN,
      PROFILES.SUPORT,
      PROFILES.ANALYTICA,
    ],
  },
  {
    element: ListActivities,
    path: '/conteudo/atividades',
    title: 'Atividades',
    profiles: [PROFILES.STUDENT],
  },
  {
    element: Activity,
    path: '/conteudo/atividades/:id',
    title: 'Conteúdo',
    profiles: [PROFILES.STUDENT],
  },
  {
    element: CreateContent,
    path: '/conteudo/novo',
    title: 'Conteúdo - Cadastro de Conteúdo',
    profiles: [
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.ADMIN,
      PROFILES.SUPORT,
      PROFILES.ANALYTICA,
    ],
  },
  {
    element: CreateContent,
    path: '/conteudo/editar',
    title: 'Conteúdo - Editar Conteúdo da Aula.',
    profiles: [
      PROFILES.TEACHER,
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.ADMIN,
      PROFILES.SUPORT,
      PROFILES.ANALYTICA,
    ],
  },
  {
    element: ContentSearch,
    path: '/conteudo/pesquisar/area-de-conhecimento',
    title: 'Conteúdo - Área de conhecimento',
    profiles: [PROFILES.STUDENT],
  },
  {
    element: SubjectSearch,
    path: '/conteudo/pesquisar/materias',
    title: 'Conteúdo - Matérias',
    profiles: [PROFILES.STUDENT],
  },
  {
    element: ThemeSearch,
    path: '/conteudo/pesquisar/temas',
    title: 'Conteúdo - Temas',
    profiles: [PROFILES.STUDENT],
  },
  {
    element: ContentRecords,
    path: '/conteudo/cadastros',
    title: 'Conteúdo - Cadastros',
    profiles: [
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.ADMIN,
      PROFILES.SUPORT,
      PROFILES.ANALYTICA,
    ],
  },
  {
    element: CalendarFunctionalities,
    path: '/agendar-atividade',
    title: 'Conteúdo - Agendar atividade',
    profiles: [PROFILES.STUDENT, PROFILES.RESPONSIBLE],
  },
  {
    element: Quiz,
    path: '/conteudo/questionario',
    title: 'Conteúdo - Questionário',
    profiles: [PROFILES.STUDENT],
  },
  {
    element: QuizDownloadPage,
    path: '/conteudo/questionario/download',
    title: 'Conteúdo - Download de questionário',
    profiles: [PROFILES.STUDENT],
  },
  {
    element: CommunicationWarnings,
    path: '/comunicacao/avisos',
    title: 'Área de avisos',
    profiles: [
      PROFILES.TEACHER,
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.ADMIN,
      PROFILES.SUPORT,
      PROFILES.ANALYTICA,
    ],
  },
  {
    element: CommunicationManagement,
    path: '/comunicacao/gestao',
    title: 'Calendário',
    profiles: [PROFILES.ANALYTICA],
  },
  {
    element: CommunicationHelpArea,
    path: '/comunicacao/area-duvidas',
    title: 'Área de dúvidas',
    profiles: [
      PROFILES.TEACHER,
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.SUPORT,
      PROFILES.ANALYTICA,
    ],
  },

  {
    element: MySimulations,
    path: '/simulados',
    title: 'Meus simulados',
    profiles: [PROFILES.STUDENT],
  },
  {
    element: Simulation,
    path: '/simulados/simulado',
    title: 'Simulado',
    profiles: [PROFILES.STUDENT],
  },
  {
    element: TestSimulation,
    path: '/simulados/gerar-simulado',
    title: 'Simulados',
    profiles: [PROFILES.STUDENT],
  },
  {
    element: ResultSimulation,
    path: '/simulados/:id',
    title: 'Simulados',
    profiles: [PROFILES.STUDENT],
  },
  {
    element: Test,
    path: '/simulados/gerar-simulado/prova',
    title: 'Simulado - Prova',
    profiles: [PROFILES.STUDENT],
  },
  {
    element: Enem,
    path: '/simulados/gerar-simulado/enem',
    title: 'Simulado - Enem',
    profiles: [PROFILES.STUDENT],
  },
  {
    element: Simuladao,
    path: '/simulados/gerar-simulado/simuladao',
    title: 'Simulado - Simuladão',
    profiles: [PROFILES.STUDENT],
  },
  {
    element: GeneralSimulation,
    path: '/simulados/gerar-simulado/geral',
    title: 'Simulado - Geral',
    profiles: [PROFILES.STUDENT],
  },
  {
    element: Vestibular,
    path: '/simulados/gerar-simulado/vestibular',
    title: 'Simulado - Vestibular',
    profiles: [PROFILES.STUDENT],
  },
  {
    element: MyGraphics,
    path: '/meus-graficos',
    title: 'Meus gráficos',
    profiles: [PROFILES.STUDENT, PROFILES.RESPONSIBLE],
  },
  {
    element: Graphics,
    path: '/graficos',
    title: 'Gráficos',
    profiles: [
      PROFILES.TEACHER,
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.ADMIN,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
    ],
  },
  {
    element: DataAcess,
    path: '/dados-acesso/geral',
    title: 'Gestão de acessos',
    profiles: [
      PROFILES.ADMIN,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.TEACHER,
    ],
  },
  {
    element: DataAcessUser,
    path: '/dados-acesso/usuario',
    title: 'Gestão de acessos por usuário',
    profiles: [
      PROFILES.ADMIN,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.TEACHER,
    ],
  },
  {
    element: CreateWhiteLabel,
    path: '/white-label/cadastrar',
    title: '- White Label',
    profiles: [PROFILES.ANALYTICA, PROFILES.SUPORT],
  },
  {
    element: SelectInstituition,
    path: '/white-label',
    title: '- White Label',
    profiles: [
      PROFILES.ANALYTICA,
      PROFILES.SUPORT,
      PROFILES.ADMIN,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
    ],
  },
  {
    element: CreateWhiteLabel,
    path: '/white-label/cadastrar/:id',
    title: '- White Label',
    profiles: [PROFILES.ANALYTICA, PROFILES.SUPORT],
  },
  {
    element: ListWhiteLabel,
    path: '/white-label/consultar',
    title: 'Lista de Instituições cadastradas',
    profiles: [
      PROFILES.ANALYTICA,
      PROFILES.SUPORT,
      PROFILES.ADMIN,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
    ],
  },
  {
    element: ListWhiteLabelTrails,
    path: '/white-label/trilha-pedagogica',
    title: 'Lista de Trilhas Pedagógicas',
    profiles: [
      PROFILES.ANALYTICA,
      PROFILES.ADMIN,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.SUPORT,
    ],
  },
  {
    element: CreateWhiteLabelTrail,
    path: '/white-label/trilha-pedagogica/detalhes',
    title: 'Criar Trilha Pedagógica',
    profiles: [PROFILES.ANALYTICA, PROFILES.SUPORT],
  },
  {
    element: WhiteLabelTrailYear,
    path: '/white-label/trilha-pedagogica/ano-letivo',
    title: 'Criar Trilha Pedagógica',
    profiles: [
      PROFILES.ANALYTICA,
      PROFILES.ADMIN,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.SUPORT,
    ],
  },
  {
    element: Issues,
    path: '/comunicacao/duvidas',
    title: 'Área de dúvidas',
    profiles: [PROFILES.STUDENT, PROFILES.RESPONSIBLE],
  },
  {
    element: ConsultClient,
    path: '/clientes-instituicao/consultar',
    title: 'Lista de Instituições cadastradas',
    profiles: [
      PROFILES.ANALYTICA,
      PROFILES.ADMIN,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.SUPORT,
    ],
  },
  {
    element: ConsultSchool,
    path: '/clientes-instituicao/consultar/instituicao',
    title: 'Lista de Escolas Cadastradas',
    profiles: [
      PROFILES.ANALYTICA,
      PROFILES.ADMIN,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.SUPORT,
    ],
  },
  {
    element: ConsultSchoolYear,
    path: '/clientes-instituicao/consultar/instituicao/escola',
    title: 'Lista de Anos Letivos Cadastrados',
    profiles: [
      PROFILES.ANALYTICA,
      PROFILES.ADMIN,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.SUPORT,
    ],
  },
  {
    element: ConsultSchoolPedagogicalTrail,
    path: '/clientes-instituicao/consultar/instituicao/trilha-pedagogica',
    title: 'Trilha Pedagógica',
    profiles: [
      PROFILES.ANALYTICA,
      PROFILES.ADMIN,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.SUPORT,
    ],
  },
  {
    element: PedagogicalTrailYear,
    path: '/clientes-instituicao/consultar/instituicao/trilha-pedagogica/ano',
    title: 'Trilha Pedagógica',
    profiles: [
      PROFILES.ANALYTICA,
      PROFILES.ADMIN,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.SUPORT,
    ],
  },
  {
    element: ConsultRegisteredClasses,
    path: '/clientes-instituicao/consultar/instituicao/turmas',
    title: 'Lista de Turmas Cadastradas',
    profiles: [
      PROFILES.ANALYTICA,
      PROFILES.ADMIN,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.SUPORT,
    ],
  },

  {
    element: CreateClient,
    path: '/clientes-instituicao',
    title: 'Cadastro de Instituição',
    profiles: [PROFILES.ANALYTICA, PROFILES.SUPORT],
  },
  {
    element: CreateClient,
    path: '/instituicao/:id',
    title: 'Detalhes da Instituição',
    profiles: [
      PROFILES.ANALYTICA,
      PROFILES.SUPORT,
      PROFILES.ADMIN,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
    ],
  },
  {
    element: GoalGenerator,
    path: '/metas/gerador-de-metas',
    title: 'Gerador de metas',
    profiles: [
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.TEACHER,
      PROFILES.ANALYTICA,
      PROFILES.SUPORT,
      PROFILES.ADMIN,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
    ],
  },
  {
    element: GoalMonitor,
    path: '/metas/acompanhar-metas',
    title: 'Acompanhar metas',
    profiles: [
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.TEACHER,
      PROFILES.ANALYTICA,
      PROFILES.SUPORT,
      PROFILES.ADMIN,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
    ],
  },
  {
    element: GoalMonitorListActivities,
    path: '/metas/acompanhar-metas/atividades',
    title: 'Acompanhar metas',
    profiles: [
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.TEACHER,
      PROFILES.ANALYTICA,
      PROFILES.SUPORT,
      PROFILES.ADMIN,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
    ],
  },
  {
    element: GoalMonitorListShowContent,
    path: '/metas/acompanhar-metas/visualizar-conteudo',
    title: 'Acompanhar metas',
    profiles: [
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.TEACHER,
      PROFILES.ANALYTICA,
      PROFILES.SUPORT,
      PROFILES.ADMIN,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
    ],
  },
  {
    element: GoalConsult,
    path: '/metas/consultar-metas',
    title: 'Consultar metas',
    profiles: [
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.TEACHER,
      PROFILES.ANALYTICA,
      PROFILES.SUPORT,
      PROFILES.ADMIN,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
    ],
  },
  {
    element: GoalConsultListActivities,
    path: '/metas/consultar-metas/atividades',
    title: 'Consultar metas',
    profiles: [
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.TEACHER,
      PROFILES.ANALYTICA,
      PROFILES.SUPORT,
    ],
  },
  {
    element: GoalConsultListShowContent,
    path: '/metas/consultar-metas/visualizar-conteudo',
    title: 'Consultar metas',
    profiles: [
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.TEACHER,
      PROFILES.ANALYTICA,
      PROFILES.SUPORT,
    ],
  },
  {
    element: Activities,
    path: '/metas/gerador-de-metas/atividades',
    title: 'Gerador de metas',
    profiles: [
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.TEACHER,
      PROFILES.ANALYTICA,
      PROFILES.SUPORT,
      PROFILES.ADMIN,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
    ],
  },
  {
    element: ContentVisualization,
    path: '/metas/gerador-de-metas/visualizar-conteudo',
    title: 'Gerador de metas',
    profiles: [
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.TEACHER,
      PROFILES.ANALYTICA,
      PROFILES.SUPORT,
      PROFILES.ADMIN,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
    ],
  },
  {
    element: CreateUser,
    path: '/clientes-usuario',
    title: 'Cadastro de Usuário',
    profiles: [
      PROFILES.ANALYTICA,
      PROFILES.ADMIN,
      PROFILES.TEACHER,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.SUPORT,
    ],
  },
  {
    element: CreateUser,
    path: '/usuario/:id',
    title: 'Cadastro de Usuário',
    profiles: [
      PROFILES.ANALYTICA,
      PROFILES.ADMIN,
      PROFILES.TEACHER,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.SUPORT,
    ],
  },
  {
    element: ConsultUser,
    path: '/clientes-usuario/consultar',
    title: 'Detalhes do Usuário',
    profiles: [
      PROFILES.ANALYTICA,
      PROFILES.ADMIN,
      PROFILES.TEACHER,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.SUPORT,
    ],
  },
  {
    element: ConsultPlan,
    path: '/plano/consultar',
    title: 'Trilha de Aprendizado',
    profiles: [PROFILES.STUDENT, PROFILES.RESPONSIBLE],
  },
  {
    element: PlanDetails,
    path: '/plano/consultar/detalhes',
    title: 'Trilha de Aprendizado',
    profiles: [PROFILES.STUDENT, PROFILES.RESPONSIBLE],
  },
  {
    element: Boletim,
    path: '/boletim/meus-boletins',
    title: 'Boletim escolar',
    profiles: [PROFILES.STUDENT],
  },
  {
    element: CreateBoletim,
    path: '/boletim/gerar-boletins',
    title: 'Cadastro de boletim',
    profiles: [
      PROFILES.ADMIN,
      PROFILES.ANALYTICA,
      PROFILES.SUPORT,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
    ],
  },
  {
    element: ConsultBoletim,
    path: '/boletim/consultar-boletins',
    title: 'Consulta de boletim',
    profiles: [
      PROFILES.TEACHER,
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.ANALYTICA,
      PROFILES.ADMIN,
      PROFILES.SUPORT,
    ],
  },
  {
    element: ConsultStudentsBoletim,
    path: '/boletim/consultar-boletins-alunos',
    title: 'Consulta de Boletim Aluno',
    profiles: [
      PROFILES.TEACHER,
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.ADMIN_REGIONAL,
      PROFILES.GESTOR_REGIONAL,
      PROFILES.ANALYTICA,
      PROFILES.ADMIN,
      PROFILES.SUPORT,
    ],
  },
  {
    element: Warnings,
    path: '/comunicacao/estudante-avisos',
    title: 'Avisos',
    profiles: [PROFILES.STUDENT],
  },
  {
    element: Ranking,
    path: '/ranking',
    title: 'Ranking',
    profiles: [PROFILES.STUDENT, PROFILES.RESPONSIBLE],
  },
  {
    element: MyTickets,
    path: '/ticket/meus-tickets',
    title: 'Suporte',
    profiles: [PROFILES.STUDENT],
  },
  {
    element: Tickets,
    path: '/ticket/geral',
    title: 'Suporte',
    profiles: [PROFILES.ANALYTICA, PROFILES.TEACHER_ANALYTICA, PROFILES.SUPORT],
  },
  {
    element: StudentLiveClasses,
    path: '/aluno/aula-ao-vivo',
    title: 'Aula ao Vivo',
    profiles: [PROFILES.STUDENT, PROFILES.RESPONSIBLE],
  },
  {
    element: TeacherLiveClasses,
    path: '/professor/aula-ao-vivo',
    title: 'Iniciar Aula ao Vivo',
    profiles: [PROFILES.TEACHER, PROFILES.TEACHER_ANALYTICA],
  },
  {
    element: TeacherLiveClass,
    path: '/professor/aula-ao-vivo/criar-aula',
    title: 'Iniciar Aula ao Vivo',
    profiles: [PROFILES.TEACHER, PROFILES.TEACHER_ANALYTICA],
  },
  {
    element: Live,
    path: '/aula-ao-vivo/:liveClassId',
    title: 'Aula ao Vivo',
    profiles: [
      PROFILES.TEACHER,
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.STUDENT,
      PROFILES.RESPONSIBLE,
    ],
  },
  {
    element: RecordedLive,
    path: '/aula-gravada/:liveClassId',
    title: 'Aula Gravada',
    profiles: [
      PROFILES.TEACHER,
      PROFILES.TEACHER_ANALYTICA,
      PROFILES.STUDENT,
      PROFILES.RESPONSIBLE,
    ],
  },
  {
    element: Groups,
    path: '/cadastro-grupos',
    title: 'Cadastro de Grupos',
    profiles: [PROFILES.ADMIN],
  },
];
