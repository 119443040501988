import { useModal } from 'contexts/ModalContext';

import {
  Button,
  CustomDropdown,
  Togglebutton,
  CustomDropdownMenu,
  ContentWrapper,
  Footer,
  ButtonContainer,
  NoNotification,
  RowAlert,
  RowAlertDate,
  MdDeleteIcon,
  AlertNotification,
  ButtonReaded,
} from './styles';
import InsideButton from 'components/Button/InsideButton';
import metrics from 'utils/theme';
import { useEffect, useState } from 'react';
import { FiBell } from 'react-icons/fi';
import { api } from 'services/api';
import { Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'contexts/ThemeContext';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

interface NotificationsResponse {
  notificacoes: Notifications[];
}

interface Notifications {
  id: string;
  message: string;
  createdAt: string;
  agendado: string;
  type: string;
  status: 'LIDO' | 'NAO_LIDO';
}

function NotificationBell() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setModal } = useModal();
  const { theme } = useTheme();

  const [notifications, setNotifications] = useState<Notifications[]>([]);

  const getNotifications = async () => {
    const response: NotificationsResponse = await api.get(
      `/notificacao?limite=5`
    );
    if (response && response.notificacoes) {
      setNotifications(response.notificacoes);
    }
  };

  const handleSetNotificationRead = async (id: string) => {
    await api.patch(`/notificacao/${id}`, {
      status: 'LIDO',
    });
    getNotifications();
  };

  const handleChangeNotificationStatus = async () => {
    const ids = notifications.map((notification) => notification.id);
    await api.patch(`/notificacao`, {
      ids,
    });
    getNotifications();
  };

  function notificationModal(message: string, type: string) {
    setModal({
      show: true,
      title: 'Notificação',
      size: 'lg',
      component: (
        <>
          {message.replace(/<(.|\n)*?>/g, '')}
          <Footer className="d-inline-flex justify-content-center gap-2 w-100">
            <InsideButton
              onClick={() =>
                setModal({
                  show: false,
                })
              }
              text="Voltar"
              type="button"
              width="160px"
              borderRadius={`${metrics.borderRadius.custom}`}
            />
            {type === 'aula-ao-vivo' && (
              <InsideButton
                onClick={() => {
                  setModal({
                    show: false,
                  });
                  navigate('/aluno/aula-ao-vivo');
                }}
                text="Ver aulas ao vivo"
                type="button"
                width="160px"
                borderRadius={`${metrics.borderRadius.custom}`}
              />
            )}
          </Footer>
        </>
      ),
    });
  }

  useEffect(() => {
    getNotifications();
  }, [pathname]);

  return (
    <ContentWrapper>
      {notifications?.length ? <AlertNotification /> : ''}
      <CustomDropdown>
        <Togglebutton variant="default" className="btn-lg">
          <FiBell />
        </Togglebutton>
        <CustomDropdownMenu>
          {notifications?.length > 0 ? (
            <>
              <ButtonReaded onClick={() => handleChangeNotificationStatus()}>
                Marcar todas como lidas
              </ButtonReaded>
              {notifications?.map((notification) => {
                return (
                  <ButtonContainer>
                    <Button
                      as="button"
                      onClick={() =>
                        notificationModal(
                          notification.message,
                          notification.type
                        )
                      }
                      key={notification.id}
                    >
                      <RowAlert>
                        {notification.type === 'aula-ao-vivo'
                          ? notification.message
                          : 'Você recebeu uma notificação!'}
                      </RowAlert>
                      <RowAlertDate className="justify-content-center">
                        {notification.type === 'aula-ao-vivo'
                          ? dayjs(notification.agendado).format(
                              '[às] HH:mm [de] DD/MM/YYYY'
                            )
                          : dayjs(notification.agendado).format(
                              'DD/MM/YYYY [às] HH:mm'
                            )}
                      </RowAlertDate>
                    </Button>
                    <Col>
                      <MdDeleteIcon
                        size={20}
                        color={theme.error500}
                        onClick={() =>
                          handleSetNotificationRead(notification.id)
                        }
                      />
                    </Col>
                  </ButtonContainer>
                );
              })}
            </>
          ) : (
            <NoNotification>Não há notificações</NoNotification>
          )}
        </CustomDropdownMenu>
      </CustomDropdown>
    </ContentWrapper>
  );
}

export default NotificationBell;
